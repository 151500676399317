.context-menu-container {
  position: relative;
}

.context-menu-container button {
  font-size: 0.8rem;
}

.context-menu > .block {
  border: 1px solid var(--border);
  padding: 0.5rem;
}

.context-menu .icon {
  background: var(--text);
}

.context-menu a:hover {
  color: var(--text);
}

.context-menu-open {
  cursor: pointer;
}

.context-menu-container .mask {
  background: unset;
}

.context-menu {
  color: var(--text);
  position: absolute;
  width: max-content;
  z-index: 11;
  padding-top: 1rem;
  right: 0;
}

.context-menu.left {
  left: 0;
}

.context-menu.right {
  left: unset;
  right: 0;
}

.context-menu .block {
  padding: 1rem 0;
  font-size: 0.8rem;
  background: var(--background);
}

.context-menu-info {
  padding: 0.8rem 1.2rem;
}

.context-menu-info.vertical-flex {
  gap: 0.5rem;
}

.context-menu-option {
  padding: 0.8rem 1.2rem;
  display: block;
  transition: background-color 0.2s;
}

.context-menu-option:not(._disabled):hover {
  cursor: pointer;
  color: var(--accent);
}
