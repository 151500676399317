.demo-row input {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  display: inline-block;
  outline: 0;
}

.demo-row input._editable {
  padding: 0.5rem 0;
  border-bottom: 1px var(--text) solid;
}

.audio-player {
  display: flex;
}

.audio-player:hover {
  cursor: pointer;
}

.audio-player div {
  display: inline-block;
}

.audio-player-icon {
  height: 0.8rem;
  width: 0.8rem;
  background: var(--text);
  mask-size: 80%;
  mask-repeat: no-repeat;
  mask-position: center;
  margin-right: 0.2rem;
}

.audio-player-icon._off {
  mask-image: url("/public/assets/images/play.png");
}

.audio-player-icon._on {
  mask-image: url("/public/assets/images/pause.png");
}

.audio-player:hover .audio-player-icon {
  background: var(--accent);
}

.audio-player-title {
  font-weight: 900;
}

.audio-player:hover .audio-player-title {
  color: var(--accent);
}
