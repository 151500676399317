.stepper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  padding-top: 2rem;
  user-select: none;
}

.stepper-step {
  position: relative;
  text-align: center;
  color: var(--accent);
}

.stepper-step._active {
  color: var(--text);
}

.stepper-step._current {
  font-weight: 900;
}

.stepper-step:not(:first-child)::before {
  position: absolute;
  content: "";
  height: 0.15rem;
  width: 90%;
  border-radius: 1rem;
  background: var(--border);
  right: 55%;
  top: -1rem;
}

.stepper-step._active::before {
  background: var(--text) !important;
}

.stepper-step::after {
  position: absolute;
  content: "";
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1rem;
  background: var(--border);
  top: -1.2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.stepper-step._active::after {
  background: var(--text) !important;
}
