@media (max-width: 700px) {
  nav {
    gap: unset !important;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 1.2rem !important;
    border-right: unset !important;
    border-top: 1px solid var(--border);
  }
}

nav {
  background: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 1.2rem 0 max(1.2rem, env(safe-area-inset-left));
  border-right: 1px solid var(--border);
}

.admin-nav-icon {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  box-sizing: border-box;
  position: relative;
}

.admin-nav-icon:before {
  height: 100%;
  width: 100%;
  background: var(--text);
  position: absolute;
  content: "";
  mask-size: 100%;
  mask-position: 100%;
  mask-repeat: no-repeat;
}

.admin-nav-icon:hover:before {
  background: var(--accent);
}

.admin-nav-icon._stats:before {
  mask-image: url(/public/assets/images/home.png);
}

.admin-nav-icon._stats.active:before {
  mask-image: url(/public/assets/images/home-filled.png);
}

.admin-nav-icon._products:before {
  mask-image: url(/public/assets/images/products.png);
}

.admin-nav-icon._products.active:before {
  mask-image: url(/public/assets/images/products-filled.png);
}

.admin-nav-icon._coupons:before {
  mask-image: url(/public/assets/images/coupons.png);
}

.admin-nav-icon._coupons.active:before {
  mask-image: url(/public/assets/images/coupons-filled.png);
}

.admin-nav-icon._transactions:before {
  mask-image: url(/public/assets/images/transactions.png);
}

.admin-nav-icon._transactions.active:before {
  mask-image: url(/public/assets/images/transactions-filled.png);
}

.admin-nav-icon._payouts:before {
  mask-image: url(/public/assets/images/bank.png);
}

.admin-nav-icon._payouts.active:before {
  mask-image: url(/public/assets/images/bank.png);
}

.admin-nav-icon._mail:before {
  mask-image: url(/public/assets/images/mail.png);
}

.admin-nav-icon._mail.active:before {
  mask-image: url(/public/assets/images/mail-filled.png);
}
