.product-preview {
  user-select: none;
  grid-gap: 0.5rem;
  width: 500px;
}

.product-preview img {
  max-width: 100%;
  aspect-ratio: auto;
  margin-bottom: 1rem;
}

.product-preview-tag {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  background: var(--selection);
  font-size: 0.6rem;
  margin-right: 0.5rem;
}

.product-preview-video {
  max-width: 500px;
}

.product-preview-description {
  font-size: 0.6rem;
  font-family: "Arial";
  font-weight: normal;
  letter-spacing: 0;
}

.product-preview-files {
}

.product-preview-files-title {
  font-weight: 900;
  margin-bottom: 0.5rem;
}

.product-preview-file {
  margin-bottom: 0.5rem;
}

.product-preview-file-number {
  margin-right: 0.5rem;
  font-weight: 900;
}
