@font-face {
  font-family: "Bebas Neue";
  src: url("/public/assets/font/BebasNeue-Regular.otf") format("opentype");
  font-weight: normal;
  ascent-override: 90%;
  descent-override: 20%;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("/public/assets/font/BebasNeue-Bold.otf") format("opentype");
  font-weight: bold;
  ascent-override: 90%;
  descent-override: 20%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: var(--text) var(--background);
  background: var(--background2);
}

#root {
  display: flex;
  justify-content: stretch;
  height: 100vh;
  height: 100dvh;
}

@media (max-width: 700px) {
  #root {
    flex-direction: column-reverse;
  }
  #page {
    padding: 2rem 1rem !important;
  }
}

:root,
::placeholder {
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
}

:root {
  --background: #fff;
  --background2: #f9f9f9;
  --text: #000;
  --accent: #888;
  --border: #ddd;
  --opaque: rgba(0, 0, 0, 0.6);
  --radius: 2px;
  --page-width: 1080px;
  --column-width: 250px;
  --column-gap: 40px;
  --button-width: 300px;
  --content-width: 500px;
  --selection: #eee;
  --fb: #1877f2;
  --ig: #c13584;
  --tw: #1da1f2;
  --yt: #ff0000;
  --sp: #1db954;
  --sc: #ff7700;
  color: var(--text);
}

.page-wrapper.vertical-flex {
  overflow-y: auto;
  gap: 0;
}

#page {
  padding: 2rem;
  padding-right: max(2rem, env(safe-area-inset-right));
}

.container {
  /* max-width: var(--page-width); */
  margin: auto;
  gap: 0 var(--column-gap);
}

:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

a {
  text-decoration: none;
  color: var(--text);
}

a:hover {
  text-decoration: underline;
}

img {
  display: block;
  object-fit: contain;
}

select {
  padding: 0.5rem;
  border: 1px solid var(--border);
  border-radius: var(--radius);
}

input {
  cursor: inherit;
  border-radius: var(--radius);
}

label {
  cursor: inherit;
  font-weight: bold;
  font-size: 0.8rem;
  display: block;
}

.select-option {
  padding: 1rem;
}

s {
  padding-right: 1ch;
}

.embed-player {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

textarea {
  font-family: "Arial";
  resize: none;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  padding: 0.5rem;
  width: 100%;
}

::selection {
  background-color: rgba(0, 0, 0, 0.1);
}

::placeholder {
  color: var(--accent);
}

.mask {
  background: var(--opaque);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.divider {
  height: 1px;
  background-color: var(--text);
}

.divider._light {
  background-color: var(--border);
}

.mb-half {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flex.start {
  align-items: start;
}

.flex.small {
  gap: 0.5rem;
}

.flex.no-gap {
  gap: 0rem;
}

.flex.center {
  justify-content: center;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.reverse {
  flex-direction: row-reverse;
}

.grow {
  flex-grow: 1;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

form.vertical-flex {
  gap: 1rem;
}

.page-column {
  margin-bottom: 2rem;
}

.page-column._nav {
  margin-bottom: unset;
}

.hover {
  user-select: none;
  cursor: pointer;
}

.hover:hover {
  color: var(--accent);
}

/* all buttons */
button {
  font-size: inherit;
  border: unset;
  font-family: unset;
  background: unset;
  letter-spacing: inherit;
  color: var(--text);
  text-align: inherit;
  cursor: pointer;
  user-select: none;
}

button:not(.button):not(.block):not(:disabled):not(._disabled):hover {
  color: var(--accent);
}

/* just rectangle buttons */
.button {
  user-select: none;
  color: var(--background);
  width: 100%;
  max-width: var(--button-width);
  border-radius: var(--radius);
  text-align: center;
  background: var(--text);
  padding: 1rem 0;
  height: min-content;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  display: block;
}

.button._disabled,
.button:disabled {
  color: var(--background);
  background: var(--accent);
  cursor: default;
}

.button:hover,
.button:focus {
  background: var(--accent);
  text-decoration: none;
  outline: none;
}

.button-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.button-group > * {
  margin: auto;
}

.product-form.grid {
  grid-template-columns: 1fr auto;
  grid-auto-flow: row-reverse;
  gap: 2rem;
}

.date-picker {
  border: 1px solid var(--border);
  border-radius: var(_--radius);
  box-sizing: border-box;
  font-family: Bebas Neue;
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 0.5rem;
  width: 100%;
}

.dropdown-arrow {
  height: 12px;
  width: 12px;
  background: var(--accent);
  mask-image: url("/public/assets/images/arrow.png");
  mask-size: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
}

.status-indicator {
  border-radius: 0.25rem;
  padding: 0.5rem;
  text-align: center;
}

.status-indicator._green {
  background: rgba(64, 255, 64, 0.2);
}

.status-indicator._yellow {
  background: rgba(255, 255, 0, 0.2);
}

.status-indicator._red {
  background: rgba(255, 64, 64, 0.2);
}

.error {
  color: red;
  text-align: center;
  font-weight: bold;
}

:not(.vertical-flex) > .error {
  margin-bottom: 2rem;
}

.status-container > *:not(:last-child) {
  margin-bottom: 1rem;
}

.status {
  position: relative;
}

.status.center {
  text-align: center;
}

.status:after {
  position: absolute;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: loading 1s linear infinite alternate;
  content: ""; /* ascii code for the ellipsis character */
}

.progress {
  border: 1px solid var(--text);
  height: 0.5rem;
  background: var(--text);
  max-width: var(--button-width);
  margin: auto;
}

.loading {
  padding: 1rem;
  text-align: center;
  position: relative;
}

.loading:after {
  position: absolute;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: loading 1s linear infinite alternate;
  content: ""; /* ascii code for the ellipsis character */
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp._2 {
  -webkit-line-clamp: 2;
}

.line-clamp._1 {
  -webkit-line-clamp: 2;
}

@keyframes loading {
  0% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--text);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--accent);
}

@media (max-width: 700px) {
  .col-layout {
    flex-direction: column !important;
  }
  .col-nav {
    flex-direction: row !important;
    margin: -1rem;
  }
  .col-nav-option {
    padding: 1rem !important;
  }
  .col-nav-option.active:after {
    left: 0.5rem !important;
  }
}

.col-layout {
  display: flex;
  flex-direction: row;
  gap: 3rem 5rem;
}

.col-nav {
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-start;
}

.col-nav-option {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  gap: 0.5rem;
  padding: 1rem 0;
}

.col-nav-option.active:after {
  content: "";
  position: absolute;
  height: 0.2rem;
  width: 0.2rem;
  border-radius: 0.1rem;
  background-color: var(--text);
  left: -0.5rem;
  top: 50%;
  transform: translate(0, -50%);
}

.col {
  flex-grow: 1;
}

.block {
  padding: 3rem;
  border-radius: var(--radius);
  color: var(--text);
  background-color: var(--background);
  margin-bottom: 2rem;
  border: 1px solid var(--border);
}

.block > :last-child {
  margin-bottom: unset;
}

.react-datepicker-wrapper {
  display: block;
}

@media (max-width: 700px) {
  .block {
    padding: 1rem !important;
  }
}
