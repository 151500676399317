@media (max-width: 700px) {
  #pop-up-container {
    padding: 2rem !important;
  }
}

#pop-up {
  z-index: 20;
  padding: 1rem;
  overflow-y: auto;
}

#pop-up-container {
  position: relative;
  padding: 4rem;
  background: var(--background);
  border-radius: var(--radius);
  width: 100%;
  max-width: 600px;
  margin: auto;
}

#pop-up-wrapper > :last-child {
  margin-bottom: unset;
}

.pop-up-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
