@media (max-width: 700px) {
  .grid-row {
    gap: 0 1rem !important;
  }
}

.grid {
  display: grid;
}

.grid._2 {
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
}

.grid-row {
  display: grid;
  grid-template-columns: subgrid;
  grid-auto-flow: column;
  grid-column: 1 / -1;
  align-items: center;
  gap: 0 2rem;
  padding: 0.5rem;
}

.grid-row:not(._header) {
  border-top: 1px solid var(--border);
}

button.grid-row:hover {
  background: #f6f6f6;
  color: inherit !important;
}

.grid-row > span,
.grid-row > button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grid-button {
  padding: 0.5rem;
  max-width: max-content;
}

.grid-button:hover {
  text-decoration: unset;
  color: var(--accent);
}

.grid-expanded {
  grid-column: 1/-1;
  grid-row: 2;
  padding: 2rem;
}

.grid-length {
  float: right;
}
