/* time picker */
.picker.flex {
  user-select: none;
  gap: 0;
}

.picker button {
  padding: 0.5rem;
}

.picker-option._selected {
  text-decoration: underline;
}
