.coupon-form {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
}

.coupon-form-products {
  grid-column: 1/-1;
}

.coupon-form-discount {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
}

.coupon-form-discount > :not(:last-child) {
  margin-right: 1rem;
}

.coupon-form-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.coupon-form-wrapper .input {
  flex-grow: 1;
  margin-left: 0.2rem;
}
