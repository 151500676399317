@media (max-width: 700px) {
  #notification-manager {
    bottom: 3rem !important;
  }
}

#notification-manager {
  position: fixed;
  padding: 2rem;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  z-index: 10;
}

.notification {
  width: var(--button-width);
  max-width: 100%;
  margin: auto;
  background: var(--background);
  border: 1px solid var(--text);
  color: var(--text);
  font-size: 0.8rem;
  margin: auto;
  padding: 1rem;
  margin-bottom: 1rem;
}

.notification > *:not(:last-child) {
  margin-bottom: 0.5rem;
}
