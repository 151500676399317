.stats-graph {
  height: 20rem;
  position: static;
  width: 100%;
}

.stats-no-sales {
  text-align: center;
  margin: 2rem 0;
}

.block._stats-banner {
  justify-content: space-evenly;
}

.stats-banner-divider {
  height: 3rem;
  width: 1px;
  background: var(--border);
}

.stats-banner-col-subtitle {
  font-size: 0.8rem;
}

.stats-comparison-grid-row {
  display: grid;
  grid-template-columns: 10fr 1fr 1fr;
  grid-gap: 2rem;
  line-height: 2rem;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-top: 1px solid var(--border);
}

.stats-comparison-grid-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stats-details-clear {
  font-size: 0.8rem;
  margin-left: 2rem;
  line-height: 2.5rem;
}

.stats-details-clear:hover {
  cursor: pointer;
  user-select: none;
  color: var(--accent);
}
