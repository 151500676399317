.input {
  cursor: text;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  height: min-content;
  background: var(--background);
  gap: 0 !important;
}

.input-icon {
  display: inline-block;
  background: var(--accent);
  height: 0.7rem;
  width: 0.7rem;
  margin-left: 0.5rem;
  mask-size: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
}

.input-field {
  display: inline-block;
  vertical-align: top;
  font-size: 0.8rem;
  line-height: 1rem;
  border: 0;
  color: var(--text);
  outline: 0;
  padding: 0.5rem;
  background: unset;
  flex-grow: 1;
}

.input-icon._search {
  mask-image: url("/public/assets/images/search.png");
}
