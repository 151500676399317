.cart-row-image {
  max-width: 100px;
}

.cart-row-col {
  flex-grow: 1;
}

.cart-row-title {
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0.5rem;
}

.cart-row-subtitle {
  font-size: 0.8rem;
}
