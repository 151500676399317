.select {
  width: fit-content;
}

.select-button {
  font-size: 0.8rem;
  border: 1px solid var(--border);
  padding: 0.5rem;
  min-width: 7rem;
}

.select-value {
  flex-grow: 1;
  white-space: nowrap;
  line-height: 1rem;
}

.select .context-menu {
  min-width: 100%;
}

.select .context-menu .block {
  max-height: 20rem;
  overflow-y: auto;
}

.select-option {
  cursor: pointer;
  padding: 0.8rem 1.2rem;
  display: block;
  transition: color 0.2s;
  color: var(--text2);
}

.select-option.active {
  color: var(--text);
}

.select-option:hover {
  color: var(--accent);
}

.voice-select-options {
  background: var(--background);
  padding: 0.4rem;
  overflow-y: auto;
  max-height: 10rem;
}

.voice-select-option {
  display: inline-block;
  background: var(--background2);
  padding: 0.4rem;
  margin: 0.4rem;
}

.voice-select-option:hover,
.voice-select-option._active {
  background: var(--accent);
}
