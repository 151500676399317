.icon {
  display: block;
  background-color: var(--text);
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.icon.hover:hover,
.icon.hover:focus,
.hover:hover > .icon,
.hover:focus > .icon,
.hover-icons:hover > .icon,
.hover-icons:focus > .icon,
button.icon:hover,
button.icon:focus {
  cursor: pointer;
  background-color: var(--accent);
}

button.icon:disabled {
  cursor: unset;
  background-color: var(--accent);
}

.icon._small {
  height: 0.8rem;
  width: 0.8rem;
}

.icon._large,
.icon.large {
  height: 1.2rem;
  width: 1.2rem;
}

.icon.plus {
  mask-image: url("/public/assets/icons/plus.png");
}

/* 
.icon.search {
  mask-image: url("/assets/images/search.png");
}

.icon.submit {
  mask-image: url("/assets/images/submit.png");
}

.icon.volume {
  box-sizing: border-box;
  mask-image: url("/assets/images/speaker.png");
}

.icon.mute {
  mask-image: url("/assets/images/mute.png");
}

.icon.card {
  mask-image: url("/assets/images/card.png");
}

.icon.back {
  transform: scaleX(-1);
  mask-image: url("/assets/images/skip.png");
}

.icon.play {
  mask-image: url("/assets/images/play.png");
}

.icon.pause {
  mask-image: url("/assets/images/pause.png");
}

.icon.skip {
  mask-image: url("/assets/images/skip.png");
}

.icon.arrow {
  mask-image: url("/assets/images/arrow2.png");
}

.icon.fb {
  background: var(--fb);
  mask-image: url("/assets/images/facebook.png");
}

.icon.tw {
  background: var(--tw);
  mask-image: url("/assets/images/twitter.png");
}

.icon.ig {
  background: var(--ig);
  mask-image: url("/assets/images/instagram.png");
}

.icon.sc {
  background: var(--sc);
  mask-image: url("/assets/images/soundcloud.png");
}

.icon.sp {
  background: var(--sp);
  mask-image: url("/assets/images/spotify.png");
}

.icon.yt {
  background: var(--yt);
  mask-image: url("/assets/images/youtube.png");
} */
